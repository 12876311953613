import React, { Component } from "react";
import Header from "../components/global/Header";
import Footer from "../components/global/Footer";

import {
  auth,
  db,
  onSnapshot,
  query,
  collection,
  orderBy,
} from "../services/firebase";

import DashboardSummary from "../components/main-sections/DashboardSummary";
import Contacts from "../components/main-sections/Contacts";

import Sidebar from "../components/global/Sidebar";
import SwitchComponents from "../helpers/switchComponents";
import Users from "../components/main-sections/Users";
import Fees from "../components/main-sections/Fees";
import Help from "../components/main-sections/Help";

let unSubSnapshot = {};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDataLoadCompleted: false,
      currentUserDetails: auth.currentUser,
      activeComponent: "dashboard-summary",
      appData: {},
    };

    this.dashboardAreaRef = React.createRef();
  }

  async addCollectionSnapshot(collection, collQuery) {
    /* build any sort and where queries */

    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[collection] = onSnapshot(collQuery, (collSnapshot) => {
          let setStateData = [];
          let appData = this.state.appData;

          collSnapshot.forEach((doc) => {
            let snapshotData = [];
            snapshotData = doc.data();
            snapshotData["key"] = doc.id;
            setStateData.push(snapshotData);
          });

          appData[collection] = setStateData;

          this.setState({ appData });
          resolve(true);
        });
      });
    } catch (error) {
      this.setState({ readError: error.message });
    }
  }

  async addDocumentSnapshot(dbRef, docId) {
    /* add snapshot data then set state */

    try {
      return new Promise((resolve, reject) => {
        unSubSnapshot[docId] = dbRef.doc(docId).onSnapshot((doc) => {
          const appData = {
            ...this.state.appData,
            [docId]: doc.data(),
          };

          this.setState({ appData });
          resolve(true);
        });
      });
    } catch (error) {
      this.setState({ readError: error.message });
      console.log(error);
    }
  }

  handleSwitchComponent = (selectedComponent) => {
    this.setState({ activeComponent: selectedComponent });
  };

  async componentDidMount() {
    this.setState({ isDataLoadCompleted: false, readError: false });

    try {
      await Promise.all(
        [
          this.addCollectionSnapshot(
            "contacts",
            query(collection(db, "contacts"), orderBy("first_name"))
          ),
        ],
        [
          this.addCollectionSnapshot(
            "fees",
            query(collection(db, "fees"), orderBy("calendar_year", "desc"))
          ),
        ],
        [
          this.addCollectionSnapshot(
            "users",
            query(collection(db, "users"), orderBy("display_name"))
          ),
        ],
        [
          this.addCollectionSnapshot(
            "permission_groups",
            query(collection(db, "permission_groups"), orderBy("group_level"))
          ),
        ]
      ).then((result) => {
        this.setState({ isDataLoadCompleted: true });
      });
    } catch (error) {
      this.setState({ isDataLoadCompleted: true, readError: error.message });
      console.log(error);
    }
  }

  async componentWillUnmount() {
    // remove listeners
    Object.keys(unSubSnapshot).forEach(function (key) {
      unSubSnapshot[key]();
    });
  }

  isDataLoaded = () => {
    const appData = this.state.appData;

    if (appData.users) {
      return true;
    }
    return false;
  };

  render() {
    const isDataLoadCompleted = this.state.isDataLoadCompleted;
    const readError = this.state.readError;
    const currentUserDetails = this.state.currentUserDetails;
    const appData = this.state.appData;

    return (
      <div className="global-background">
        <Header />

        <div className="d-flex flex-row">
          {!isDataLoadCompleted ? (
            <div
              className="spinner-border text-success isDataLoadCompleted-indicator"
              role="status"
            >
              <span className="sr-only">Loading... </span>
            </div>
          ) : (
            <React.Fragment>
              {!readError ? (
                <>
                  <Sidebar
                    onSwitchComponent={this.handleSwitchComponent}
                    currentUserDetails={this.state.currentUserDetails}
                    usersList={this.state.appData.users}
                    permissionGroups={this.state.appData.permission_groups}
                  />
                  <div className="col text-left">
                    <h3 className="ml-3 mt-3">
                      Welcome{" "}
                      {currentUserDetails ? (
                        <span>
                          <strong>{currentUserDetails.displayName}</strong>
                        </span>
                      ) : (
                        ""
                      )}{" "}
                      to the Summerland Christian College CRM
                    </h3>
                    <hr />

                    {/* SwitchComponents need more than 1 child element */}
                    <SwitchComponents active={this.state.activeComponent}>
                      <DashboardSummary
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name="dashboard-summary"
                      />

                      <Fees
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name="fees"
                      />

                      <Users
                        appData={appData}
                        currentUserDetails={currentUserDetails}
                        name="users"
                      />

                      <Help
                        currentUserDetails={currentUserDetails}
                        name="help"
                      />

                      <div name="blank">&nbsp;</div>
                    </SwitchComponents>

                    <Footer />
                  </div>
                </>
              ) : (
                { readError }
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export default Dashboard;
