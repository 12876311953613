import { initializeApp } from "firebase/app";

import {
  getAuth,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import {
  getFirestore,
  collection,
  doc,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  where,
  query,
  onSnapshot,
  orderBy,
  Timestamp,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCQCbLtFmIiRupoLindZCOVB-9D6g1EgbI",
  authDomain: "scc-crm.firebaseapp.com",
  projectId: "scc-crm",
  storageBucket: "scc-crm.appspot.com",
  messagingSenderId: "676969157827",
  appId: "1:676969157827:web:20ac0d0294be6e4ced3e76",
  measurementId: "G-CVG85LDB3F",
};

const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const googleProvider = new GoogleAuthProvider();

const getServerTimeNow = async () => {
  return Timestamp.now();
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;

    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) {
      await setDoc(doc(db, "users", user.uid), {
        uid: user.uid,
        display_name: user.displayName,
        auth_provider: "google",
        email: user.email,
        status: "new",
        allowed_access: false,
        mobile_phone: "",
        permission_groups: [],
        notes: "new user added",
        version: 1,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const loginInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(email, password);
    const user = res.user;
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      display_name: user.displayNamename,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordResetEmail = async (email) => {
  try {
    await sendPasswordResetEmail(email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = async () => {
  console.log("signing out... ");
  await signOut(auth);
};

/* function currentUserDetails(user) {
  
  let userDetail = {};
  try {

    const docRef = doc(db, "users", user?.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      userDetail = docSnap.data();
    } else {
      console.log("no user found");
    }
       
    } catch (err) {
      console.error(err);
      alert("An error occured while fetching user data");
    }
  return userDetail;
}
*/

export {
  auth,
  db,
  signInWithGoogle,
  loginInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  onSnapshot,
  logout,
  collection,
  doc,
  addDoc,
  getDoc,
  deleteDoc,
  getDocs,
  setDoc,
  where,
  query,
  orderBy,
  getServerTimeNow,
};
