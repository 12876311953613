import React, { Component } from 'react';
import Header from '../components/global/Header';
import Footer from '../components/global/Footer';

import { db, auth } from '../services/firebase';
import ContactDetailForm from '../components/forms/ContactDetailForm';

class ProfileDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentUserDetails: this.props.currentUserDetails,
      loadingProfileDetails: true,
      dbOperationMessage: '',
    };
    this.accountManagerAreaRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    //   this.handleDashboardClick = this.handleDashboardClick.bind(this);
    //   this.handleSwitchComponent = this.handleSwitchComponent.bind(this);
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingProfileForm: true });
  }

  handleDashboardButtonClick() {
    window.location = '/dashboard';
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let currentUserDetails = { ...this.state.currentUserDetails };

    if (target.type === 'checkbox') {
      currentUserDetails[name] = target.checked;
    } else {
      currentUserDetails[name] = value;
    }
    this.setState({ currentUserDetails });
  };

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ dbOperationMessage: 'Saving changes.....' });

    /* update database and return message..... */
    try {
      const currentUserDetails = this.state.currentUserDetails;

      await db
        .collection('users')
        .doc(this.props.currentUserDetails['key'])
        .update(currentUserDetails)
        .then(() => {
          this.setState({ dbOperationMessage: 'Data saved....' });
          const timer = setTimeout(() => {
            this.setState({ dbOperationMessage: '' });
          }, 1000);
          return () => clearTimeout(timer);
        });
    } catch (error) {
      this.setState({
        dbOperationMessage: 'Data NOT saved....' + error.message,
        readError: error.message,
      });
    }
  }

  render() {
    const currentUserDetails = this.state.currentUserDetails;
    console.log(currentUserDetails);
    return (
      <div>
        <Header />

        <div className='d-flex flex-row'>
          <div className='col text-left'>
            <h3 className='mt-3'>
              User Details{' '}
              {currentUserDetails ? (
                <React.Fragment>
                  {' '}
                  <span>
                    for <strong>{currentUserDetails.display_name}</strong>
                  </span>
                  <div
                    onClick={() => this.handleDashboardButtonClick()}
                    className='btn btn-outline-dark float-right'>
                    Continue to Mums and Cubs Dashboard
                  </div>
                </React.Fragment>
              ) : (
                ''
              )}
            </h3>

            <hr />

            <h3>My User Profile</h3>
            <p>Please complete your details below.</p>
            <p>
              Your access to the portal is currently:{' '}
              {this.props.currentUserDetails.access_allowed
                ? 'Granted'
                : 'Pending'}
            </p>

            {this.state.dbOperationMessage ? (
              <p className='text-success'>{this.state.dbOperationMessage}</p>
            ) : null}
            {this.state.error ? (
              <p className='text-danger'>{this.state.error}</p>
            ) : null}

            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='form-group'>
                <label className='form_label' htmlFor='display_name'>
                  Display Name
                </label>
                <input
                  className='form-control'
                  placeholder='Display Name'
                  name='display_name'
                  type='text'
                  onChange={this.handleInputChange}
                  value={currentUserDetails.display_name || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='mobile_phone'>
                  Mobile Phone
                </label>
                <input
                  className='form-control'
                  placeholder='Mobile Phone'
                  name='mobile_phone'
                  type='text'
                  onChange={this.handleInputChange}
                  value={currentUserDetails.mobile_phone || ''}></input>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='email_address'>
                  Email Address
                </label>
                <input
                  readOnly={true}
                  className='form-control'
                  placeholder='Email Address'
                  name='email_address'
                  onChange={this.handleInputChange}
                  value={currentUserDetails.email_address || ''}></input>
              </div>

              <div
                onClick={this.handleSubmit}
                className='small btn btn-success mx-5'>
                Save Profile Changes
              </div>

              <div className='form-group'></div>
            </form>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default ProfileDetails;
