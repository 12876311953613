import React, { Component } from "react";
import logo from "../images/summerland-logo-transparent-401.png";
import { Link } from "react-router-dom";
import { signInWithGoogle } from "../services/firebase";
import { currentAuthUser } from "../helpers/db";

export default class HomePage extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    console.log(currentAuthUser());
    if (currentAuthUser()) {
      window.location = "/dashboard";
    }
  }

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    return (
      <div className="home">
        <section>
          <div className="jumbotron jumbotron-fluid py-5 home-background">
            <div className="container text-center py-5 ">
              {<img src={logo} className="title-logo" alt="Logo" />}
              <h1 className="site-title display-10">
                Summerland Christian College CRM
              </h1>
              <div className="container">
                <form
                  className="mt-5 py-5 px-5"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <button
                    className="btn btn-danger mr-2"
                    type="button"
                    onClick={signInWithGoogle}
                  >
                    Sign in with your school account
                  </button>
                  <p>&nbsp;</p>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
