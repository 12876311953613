//Import dayjs for date and time
import dayjs from "dayjs";
import {
  auth,
  db,
  collection,
  doc,
  addDoc,
  getDocs,
  setDoc,
  deleteDoc,
  getServerTimeNow,
} from "../services/firebase";
import {
  DeleteRowButton,
  CopyRowButton,
  ToggleSwitch,
} from "./globalHelperComponents";

/* CONTACTS START */

export const newContactDetail = {
  first_name: "",
  last_name: "",
  phone_number: "",
  email_address: "",
  notes: "",
  street_address: "",
  suburb: "",
  state: "",
  postcode: "",
  children_details: "",
  sms_reminders: false,
  on_facebook: false,
  photo_consent_status: "no",
  photo_consent_date: "",
  church_attendee: false,
  receive_church_information: false,
  partner_name: "",
  care_leader: "",
  status: "new",
  is_volunteer: false,
  is_speaker: false,
  speaker_topics: "",
  version: 1,
};

export function returnContactsColumnsDef(appData) {
  let contactsTableDef = {
    columns: [
      {
        dataField: "contact_name",
        text: "Name",
        sort: true,
        hidden: true,
      },
      {
        dataField: "key",
        text: "Contact ID",
        sort: true,
        hidden: true,
      },
      {
        dataField: "first_name",
        text: "First Name",
        sort: true,
        hidden: false,
      },
      {
        dataField: "last_name",
        text: "Last Name",
        sort: true,
        hidden: false,
      },
      {
        dataField: "phone_number",
        text: "Phone",
        sort: true,
      },
      {
        dataField: "care_leader",
        text: "Care Leader",
        sort: true,
        formatter: (cell) => {
          let careLeaderName = "None";

          if (cell && cell !== "none") {
            careLeaderName = appData.leaders.filter((leader) => {
              return leader.key === cell;
            });

            careLeaderName = careLeaderName[0].display_name;
          }
          return careLeaderName;
        },
      },
      {
        dataField: "is_volunteer",
        text: "Is Volunteer",
        sort: true,
        formatter: (cell) => {
          return cell ? "Yes" : "";
        },
      },
      {
        dataField: "is_speaker",
        text: "Is Speaker",
        sort: true,
        formatter: (cell) => {
          return cell ? "Yes" : "";
        },
      },
    ],

    defaultSorted: [
      {
        dataField: "contact_name",
        order: "asc",
      },
    ],
  };

  return contactsTableDef;
}

/* CONTACTS END */

/* FEES START */

export const newFeesYearDetail = {
  calendar_year: "",
  notes: "",
  published: false,
  version: 1,
};

export function returnFeesYearTableDef() {
  let feesTableDef = {
    columns: [
      {
        id: "calendar_year",
        name: "Calendar Year",
        selector: (row) => row.calendar_year,
        sortable: true,
      },
      {
        id: "published",
        name: "Published",
        cell: (row) => {
          const publishedStatus = !row.published;
          const handleToggle = async () => {
            const currentTimeStamp = await getServerTimeNow();
            const currentUserId = auth.currentUser.uid;

            await setDoc(
              doc(db, "fees/", row.key),
              {
                published: publishedStatus,
                updated_by: currentUserId,
                updated: currentTimeStamp,
              },
              { merge: true }
            );
          };
          return (
            <ToggleSwitch
              onColor="#03fd3d"
              onToggle={handleToggle}
              isOn={row.published}
            />
          );
        },
        sortable: true,
      },
      {
        id: "updated",
        name: "Last Changed",
        cell: (row) => {
          if (row.updated) {
            return dayjs.unix(row.updated.seconds).format("DD/MM/YYYY HH:MM");
          }

          return "N/A";
        },
        sortable: true,
      },
      {
        id: "actions",
        name: "Actions",
        button: true,
        cell: (row) => {
          const copyConfirmMessage =
            "You are about to create a new year and a copy of all fees for the year '" +
            row.calendar_year +
            "' will be included. Select 'Yes' to continue or 'No' to cancel this operation.";

          const handleRowCopyFunction = async () => {
            const currentTimeStamp = await getServerTimeNow();
            const currentUserId = auth.currentUser.uid;

            const collectionPath = "fees/";
            const querySnapshotFees = await getDocs(
              collection(db, collectionPath)
            );

            /* create new year - find last year available and add one year */
            let lastKey = "";
            querySnapshotFees.forEach(async (snapDoc) => {
              lastKey = snapDoc.id;
            });
            const newYear = "" + (parseInt(lastKey) + 1);
            console.log(newYear);

            /* create new year entry in database  */
            await setDoc(doc(db, collectionPath, newYear), {
              calendar_year: newYear,
              notes: "",
              publised: false,
              created_by: currentUserId,
              created: currentTimeStamp,
              updated_by: currentUserId,
              updated: currentTimeStamp,
              version: 1,
            });

            /* get all sub collection entries for original row and add to new year just created */
            const subCollectionPathOriginalYear =
              collectionPath + row.calendar_year + "/fee_schedule/";
            const subCollectionPathNewYear =
              collectionPath + newYear + "/fee_schedule/";

            const querySnapshotYearLevels = await getDocs(
              collection(db, subCollectionPathOriginalYear)
            );

            querySnapshotYearLevels.forEach(async (snapDoc) => {
              let newEntryData = snapDoc.data();

              newEntryData["created_by"] = currentUserId;
              newEntryData["created"] = currentTimeStamp;
              newEntryData["updated_by"] = currentUserId;
              newEntryData["updated"] = currentTimeStamp;
              newEntryData["version"] = 1;

              console.log(subCollectionPathNewYear, snapDoc.id);

              await setDoc(doc(db, subCollectionPathNewYear, snapDoc.id), {
                newEntryData,
              });
            });
          };

          const handleRowDeleteFunction = async () => {
            const collectionPath = "fees/";
            const subCollectionPath =
              collectionPath + row.calendar_year + "/fee_schedule/";

            /* check for any docs in the the sub collection to delete first:  /fees/"calendar_year"/fee_schedule/  */
            const querySnapshot = await getDocs(
              collection(db, subCollectionPath)
            );
            querySnapshot.forEach(async (snapDoc) => {
              await deleteDoc(doc(db, subCollectionPath, snapDoc.id));
            });

            /* then delete the main "calendar_year" document  */

            await deleteDoc(doc(db, collectionPath, row.key));
            return true;
          };
          return (
            <>
              <CopyRowButton
                confirmMessage={copyConfirmMessage}
                onCopyButtonClicked={handleRowCopyFunction}
              />
              <DeleteRowButton
                deleteNoRecycle={true}
                onDeleteButtonClicked={handleRowDeleteFunction}
              />
            </>
          );
        },
      },
    ],

    handleRowClicked: (row) => {
      this.setState({
        selectedItemDetail: row,
        isNew: false,
      });
    },
  };

  return feesTableDef;
}

/* FEES END */

/* FEE SCHEDULE START */

export const newFeeScheduleDetail = {
  calendar_year: "",
  chromebook_levy: "0.00",
  levies: "0.00",
  per_day_rate: "0.00",
  short_name: "",
  tuition_with_1: "0.00",
  tuition_with_2: "0.00",
  tuition_with_3: "0.00",
  tuition_with_4: "0.00",
  year_level: "",
  version: 1,
};

export function returnFeeScheduleTableDef() {
  let feeScheduleTableDef = {
    columns: [
      {
        id: "calendar_year",
        name: "Calendar Year",
        selector: (row) => row.calendar_year,
        omit: true,
      },
      {
        id: "year_level",
        name: "Year Level",
        selector: (row) => row.year_level,
        sortable: true,
      },
      {
        id: "tuition_with_1",
        name: "1",
        selector: (row) => row.tuition_with_1,
        sortable: true,
      },
      {
        id: "tuition_with_2",
        name: "2",
        selector: (row) => row.tuition_with_2,
        sortable: true,
      },
      {
        id: "tuition_with_3",
        name: "3",
        selector: (row) => row.tuition_with_3,
        sortable: true,
      },
      {
        id: "tuition_with_4",
        name: "4",
        selector: (row) => row.tuition_with_4,
        sortable: true,
      },
      {
        id: "levies",
        name: "Levies",
        selector: (row) => row.levies,
        sortable: true,
      },
      {
        id: "chromebook_levy",
        name: "Chromebook",
        selector: (row) => row.chromebook_levy,
        sortable: true,
      },
      {
        id: "per_day_rate",
        name: "Per Day",
        selector: (row) => row.per_day_rate,
        sortable: true,
      },
      {
        id: "updated",
        name: "Last Changed",
        cell: (row) => {
          if (row.updated) {
            return dayjs.unix(row.updated.seconds).format("DD/MM/YYYY HH:MM");
          }

          return "N/A";
        },
        sortable: true,
      },
      {
        id: "actions",
        name: "Actions",
        button: true,
        cell: (row) => {
          const copyConfirmMessage =
            "You are about to create a new fee schedule entry. Select 'Yes' to continue or 'No' to cancel this operation.";

          const handleRowCopyFunction = async () => {
            const currentTimeStamp = await getServerTimeNow();
            const currentUserId = auth.currentUser.uid;

            const collectionPath =
              "fees/" + row.calendar_year + "/fee_schedule/";

            row["year_level"] = row.year_level + " (copy)";
            row["version"] = 1;
            row["created"] = currentTimeStamp;
            row["created_by"] = currentUserId;
            row["updated"] = currentTimeStamp;
            row["updated_by"] = currentUserId;

            const newRowData = row;

            console.log(newRowData);

            await addDoc(collection(db, collectionPath), {
              ...newRowData,
            });
          };

          const handleRowDeleteFunction = async () => {
            const collectionPath =
              "fees/" + row.calendar_year + "/fee_schedule/";

            /* then delete the main "calendar_year" document  */

            await deleteDoc(doc(db, collectionPath, row.key));
            return true;
          };
          return (
            <>
              <CopyRowButton
                confirmMessage={copyConfirmMessage}
                onCopyButtonClicked={handleRowCopyFunction}
              />
              <DeleteRowButton
                deleteNoRecycle={true}
                onDeleteButtonClicked={handleRowDeleteFunction}
              />
            </>
          );
        },
      },
    ],
  };

  return feeScheduleTableDef;
}

/* FEE SCHEDULE END */

/* ROLES START */

export const newRoleDetail = {
  contacts_involved: [],
  role_name: "",
  role_description: "",
  notes: "",
  status: "new",
  version: 1,
};

export function returnRolesColumnsDef(appData) {
  let locationsTableRef = {
    columns: [
      {
        dataField: "role_name",
        text: "Name",
        sort: true,
      },
      {
        dataField: "key",
        text: "Roles ID",
        sort: true,
        hidden: true,
      },
      {
        dataField: "role_description",
        text: "Role Description",
        sort: true,
        hidden: false,
      },
    ],

    defaultSorted: [
      {
        dataField: "role_name",
        order: "asc",
      },
    ],
  };

  return locationsTableRef;
}

/* ROLES END */

/* USERS START */

export const newUserDetail = {
  display_name: "",
  mobile_phone: "",
  email_address: "",
  notes: "",
  access_allowed: false,
  permission_levels: [],
  version: 1,
};

export function returnUsersColumnsDef(appData) {
  let usersTableDef = {
    columns: [
      {
        dataField: "display_name",
        text: "Name",
        sort: true,
        hidden: false,
      },
      {
        dataField: "key",
        text: "User ID",
        sort: true,
        hidden: true,
      },
      {
        dataField: "mobile_phone",
        text: "Phone",
        sort: true,
      },
      {
        dataField: "email_address",
        text: "Email",
        sort: true,
      },
      {
        dataField: "access_allowed",
        text: "Access",
        sort: true,
        formatter: (cell) => {
          return cell ? "Yes" : "";
        },
      },
    ],

    defaultSorted: [
      {
        dataField: "display_name",
        order: "asc",
      },
    ],
  };

  return usersTableDef;
}

export function returnUsersColDefMaterial() {
  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Email",
      field: "email",
    },
  ];
  return columns;
}

/* USERS END */
