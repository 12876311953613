import {
  db,
  auth,
  collection,
  doc,
  addDoc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
  getServerTimeNow,
} from "../services/firebase";

export function currentAuthUser() {
  return auth.currentUser;
}

export async function createDocCustomID(collectionPath, docId, dataObj) {
  const currentFirestoreTimeStamp = await getServerTimeNow();

  dataObj["created"] = currentFirestoreTimeStamp;
  dataObj["created_by"] = auth.currentUser.uid;
  dataObj["updated"] = currentFirestoreTimeStamp;
  dataObj["updated_by"] = auth.currentUser.uid;
  return await setDoc(doc(db, collectionPath, docId), dataObj);
}

export async function createUpdateDoc(collectionPath, docId, dataObj, isNew) {
  const currentFirestoreTimeStamp = await getServerTimeNow();

  // extra fields
  dataObj["updated"] = currentFirestoreTimeStamp;
  dataObj["updated_by"] = auth.currentUser.uid;

  if (isNew) {
    /* if new item, add created by and created datetime to data object and add to collection */
    dataObj["created"] = currentFirestoreTimeStamp;
    dataObj["created_by"] = auth.currentUser.uid;
    return await addDoc(collection(db, collectionPath), dataObj);
  } else {
    /* add new version by copy of existing item into "versions" sub collection */
    const versionCollectionPath = collectionPath + "/" + docId + "/versions/";
    addVersion(collectionPath, docId, versionCollectionPath, {}, false).then(
      () => {
        /* once existing item is copied to the "versions" sub collection, update the current item with new data */
        dataObj["version"] += 1;
        return setDoc(doc(db, collectionPath, docId), dataObj);
      }
    );
  }
}

export const recycleDoc = async (collectionPath, docId) => {
  const recycledCollPath = "/recycled/";
  const originalDocRef = doc(db, collectionPath, docId);

  /* copy doc to be recycled to the "recycled" collection   */
  copyDoc(collectionPath, docId, recycledCollPath, {})
    .then(() => {
      /* copy the "Versions" sub collection of the document being recycled   */
      const versionCollectionPath = collectionPath + "/" + docId + "/versions/";
      const recycledVersionsCollPath =
        recycledCollPath + "/" + docId + "/versions/";

      const querySnapshot = getDocs(collection(db, versionCollectionPath));
      querySnapshot.forEach((doc) => {
        copyDoc(
          versionCollectionPath,
          doc.id,
          recycledVersionsCollPath,
          {}
        ).then(() => {
          /* delete the version item */
          deleteDoc(doc(db, versionCollectionPath, doc.id));
        });
      });
    })
    .then(() => {
      /* delete the original item  */
      deleteDoc(doc(db, collectionPath, docId));
      return true;
    });
  return false;
};

export const copyDoc = async (
  collectionFromPath,
  docId,
  collectionToPath,
  addData
) => {
  // copy the document

  try {
    const docRef = doc(db, collectionFromPath, docId);
    const docData = await getDoc(docRef);
    if (docData.exists()) {
      /* current document exists, create the document as a new document in the destination path   */
      const oldData = docData.data();

      console.log(collectionToPath, { ...oldData, ...addData });

      await setDoc(doc(db, collectionToPath, docId), {
        ...oldData,
        ...addData,
      });
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const addVersion = async (
  collectionFromPath,
  docId,
  collectionToPath,
  addData
) => {
  // copy the document

  try {
    const docRef = doc(db, collectionFromPath, docId);
    const docData = await getDoc(docRef);
    if (docData.exists()) {
      /* current document exists, create the document as a new document in the destination path   */
      const oldData = docData.data();

      console.log(collectionToPath, { ...oldData, ...addData });

      await addDoc(collection(db, collectionToPath), {
        ...oldData,
        ...addData,
      });
      return true;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};
