import React, { Component } from "react";
import DataTable from "react-data-table-component";

import SlidingPane from "react-sliding-pane";
import UserDetailForm from "../forms/UserDetailForm";
import {
  returnUsersColDefMaterial,
  returnUsersColumnsDef,
} from "../../helpers/dataObjects";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      isSlidingPaneOpen: false,
      slidingPaneTitle: "User Details",
    };
  }

  async componentDidMount() {}

  handleItemSaveDeleteButtonClick = (value) => {
    this.setState({ isSlidingPaneOpen: false });
  };
  async componentWillUnmount() {}

  render() {
    const appData = this.props.appData;
    let filteredUsers = appData["users"] ? appData["users"] : [];

    const contactsTableDef = returnUsersColumnsDef(appData);

    const columns = [
      {
        name: "Display Name",
        selector: (row) => row.display_name,
        sortable: true,
      },
      {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
      },
    ];

    const customStyles = {
      rows: {
        style: {
          backgroundColor: "none", // override the backgorund color
        },
      },
    };

    const handleRowClicked = (row) => {
      console.log("clicked", row);
      this.setState({
        selectedItemDetail: row,
        isNew: false,
        isSlidingPaneOpen: true,
      });
    };

    const ExpandedComponent = ({ data }) => (
      <UserDetailForm
        isNew={this.state.isNew}
        selectedItemDetail={data}
        appData={appData}
        onItemSaveDeleteButtonClicked={this.handleItemSaveDeleteButtonClick}
      />
    );

    return (
      <div class="main-section">
        <DataTable
          responsive
          highlightOnHover
          fixedHeaer
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          columns={columns}
          data={filteredUsers}
          theme="crm"
        />

        <SlidingPane
          className="sliding-pane"
          overlayClassName="pane-details-sliding-pane"
          isOpen={this.state.isSlidingPaneOpen}
          title={this.state.slidingPaneTitle}
          subtitle=""
          width="85%"
          from="right"
          onRequestClose={() => {
            this.setState({ isSlidingPaneOpen: false });
          }}
        >
          <UserDetailForm
            isNew={this.state.isNew}
            selectedItemDetail={this.state.selectedItemDetail}
            currentUserDetails={this.props.currentUserDetails}
            leadersList={this.props.appData.leaders}
            appData={appData}
            onItemSaveDeleteButtonClicked={this.handleItemSaveDeleteButtonClick}
          />
        </SlidingPane>
      </div>
    );
  }
}
export default Users;

/* 
 <BootstrapTable
          noDataIndication="There are no users to display here at the moment"
          classes="auto-table-layou"
          keyField="key"
          data={filteredUsers}
          columns={columns}
          striped
          hover
          condensed
          bordered={false}
          defaultSorted={defaultSorted}
          rowEvents={rowEvents}
        />
*/
