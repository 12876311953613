import React, { Component } from "react";

import { Navbar, Nav } from "react-bootstrap";

import { currentAuthUser } from "../../helpers/db";
import { logout } from "../../services/firebase";

import "../../App.css";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <header>
        <Navbar
          className="header navbar sticky-top min-vw-100"
          collapseOnSelect
          expand="lg"
          variant="dark"
        >
          <Navbar.Brand href="/dashboard">
            Summerland Christian College CRM
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              {currentAuthUser() ? (
                <div className="navbar-nav">
                  <Nav.Link
                    className="nav-item btn btn-outline-secondary mr-3"
                    href="/dashboard"
                  >
                    Dashboard
                  </Nav.Link>
                  <Nav.Link
                    className="nav-item btn btn-outline-secondary mr-3"
                    href="/"
                    onClick={() => logout()}
                  >
                    Logout
                  </Nav.Link>
                </div>
              ) : (
                <div className="navbar-nav">
                  <Nav.Link className="nav-item nav-link mr-3" href="/login">
                    Sign In
                  </Nav.Link>
                  <Nav.Link className="nav-item nav-link mr-3" href="/signup">
                    Sign Up
                  </Nav.Link>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}

export default Header;
