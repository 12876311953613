import React, { Component } from "react";
import logo from "../../images/summerland-logo-transparent-401.png";
import { Link } from "react-router-dom";

import { currentAuthUser } from "./../../helpers/db";

import {
  db,
  onSnapshot,
  query,
  collection,
  orderBy,
  where,
} from "../../services/firebase";
import ContactDetailsForm from "./ContactDetailsForm";

let unSubSnapshot = {};

export default class FeesCalc extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      dataLoaded: false,
      showResults: false,
      calcDetail: {
        max_children: 4,
        number_children_selector: [
          { value: "1", text: "1" },
          { value: "2", text: "2" },
          { value: "3", text: "3" },
          { value: "4", text: "4" },
          { value: "0", text: "5+" },
        ],
        number_of_children: "1",
        calendar_year: "",
        child_details: [],
        year_levels: [],
        total_tuition: "",
        total_levies: "",
        total_chromebook_levies: "",
        total_per_day_rate: "",
        enrolment_contact: false,
      },
    };
  }

  handleCalculateClick = async () => {
    const calcDetail = this.state.calcDetail;

    const newCalcDetail = await this.calculateFees(calcDetail);
    this.setState({ newCalcDetail, showResults: true });
  };

  calculateFees = async (calcDetail) => {
    /* sort child_details array based on short_name in descending so we start from highest student level */
    const originalChildDetails = calcDetail.child_details;
    const numChildren = calcDetail.number_of_children;

    const shortChildList = await originalChildDetails.filter(
      (item, key) => key < numChildren
    );

    const sortedChildDetails = shortChildList;

    let totalTution = 0;
    let totalLevies = 0;
    let totalChromebook = 0;
    let totalPerDayRate = 0;

    await sortedChildDetails.sort(
      (a, b) => b.year_level_short_name > a.year_level_short_name
    );

    /* loop through each child entry to get the year level id */

    sortedChildDetails.map((childDetail, childDetailKey) => {
      /* look up fee schedule for selected year level */
      const currentSelectedYear = calcDetail.calendar_year;
      const currentFeeSchedule =
        calcDetail["fees_schedule_" + currentSelectedYear];

      const filteredFeeSchedule = currentFeeSchedule.filter((feeItem) => {
        return feeItem.key === childDetail.year_level;
      });

      const child_number = childDetailKey + 1;

      if (filteredFeeSchedule && filteredFeeSchedule.length > 0) {
        const tuition_this_child =
          filteredFeeSchedule[0]["tuition_with_" + child_number];
        const levies_this_child = filteredFeeSchedule[0]["levies"];
        const chromebook_levy_this_child =
          filteredFeeSchedule[0]["chromebook_levy"];
        const per_day_rate_this_child = filteredFeeSchedule[0]["per_day_rate"];

        totalTution += parseFloat(tuition_this_child);
        totalLevies += parseFloat(levies_this_child);
        totalChromebook += parseFloat(chromebook_levy_this_child);
        totalPerDayRate += parseFloat(per_day_rate_this_child);

        let updatedChildDetail = sortedChildDetails[childDetailKey];

        updatedChildDetail["tuition"] = tuition_this_child;
        updatedChildDetail["levies"] = levies_this_child;
        updatedChildDetail["chromebook_levy"] = chromebook_levy_this_child;
        updatedChildDetail["per_day_rate"] = per_day_rate_this_child;

        originalChildDetails[childDetailKey] = updatedChildDetail;

        calcDetail["child_details"] = originalChildDetails;
      }
    });
    calcDetail["total_tuition"] = parseFloat(totalTution).toFixed(2);
    calcDetail["total_levies"] = parseFloat(totalLevies).toFixed(2);
    calcDetail["total_chromebook_levies"] =
      parseFloat(totalChromebook).toFixed(2);
    calcDetail["total_per_day_rate"] = parseFloat(totalPerDayRate).toFixed(2);

    return calcDetail;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: "" });
  };

  handleItemDetailFormChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let calcDetail = { ...this.state.calcDetail };

    if (target.type === "checkbox") {
      calcDetail[name] = target.checked;
    } else {
      calcDetail[name] = value;

      if (target.type === "select-one" && name.indexOf("child_") > -1) {
        this.updateChildYearLevel(calcDetail, name, value);
      }
    }

    calcDetail = await this.calculateFees(calcDetail);

    this.setState({ calcDetail });
  };

  updateChildYearLevel = async (calcDetail, childFieldRef, feeScheduleKey) => {
    const currentSelectedYear = calcDetail.calendar_year;
    const currentFeeSchedule =
      calcDetail["fees_schedule_" + currentSelectedYear];

    const filteredFeeSchedule = currentFeeSchedule.filter((item) => {
      return item.key === feeScheduleKey;
    });

    const currentChildDetail = calcDetail.child_details.filter((item) => {
      return item.form_element_id === childFieldRef;
    });

    let updatedChildDetail = currentChildDetail[0];

    updatedChildDetail["year_level"] = feeScheduleKey;
    updatedChildDetail["year_level_short_name"] =
      filteredFeeSchedule[0].short_name;

    const index = calcDetail.child_details.findIndex(
        (item) => item.form_element_id === childFieldRef
      ),
      childDetails = [...calcDetail.child_details];
    childDetails[index] = updatedChildDetail;
    calcDetail["child_details"] = childDetails;

    calcDetail = await this.calculateFees(calcDetail);

    this.setState({ calcDetail });
  };

  componentDidMount = async () => {
    const calcDetail = this.state.calcDetail;

    /* load fees data from the database and the fee schedule list for each year that is published=true  */
    const feesCollection = "fees";
    const collQuery = query(
      collection(db, feesCollection),
      orderBy("calendar_year", "desc"),
      where("published", "==", true)
    );

    unSubSnapshot[feesCollection] = onSnapshot(collQuery, (collSnapshot) => {
      let setStateData = [];
      let calcDetail = this.state.calcDetail;

      const feesListSize = collSnapshot.size;
      let counter = 0;

      collSnapshot.forEach((doc) => {
        let snapshotData = [];
        snapshotData = doc.data();
        snapshotData["key"] = doc.id;
        setStateData.push(snapshotData);

        const currentKey = doc.id;

        /* load the fee schedule for the selected year */
        const collectionName = "fees_schedule_" + currentKey;
        const collectionPath = "fees/" + currentKey + "/fee_schedule/";

        const collectionQuery = query(
          collection(db, collectionPath),
          orderBy("short_name", "desc")
        );

        unSubSnapshot[collectionName] = onSnapshot(
          collectionQuery,
          (collSnapshot) => {
            let feeScheduleData = [];

            let calcDetail = this.state.calcDetail;

            collSnapshot.forEach((doc) => {
              let snapshotData = [];
              snapshotData = doc.data();
              snapshotData["key"] = doc.id;
              feeScheduleData.push(snapshotData);
            });

            calcDetail[collectionName] = feeScheduleData;

            if (counter === feesListSize) {
              /* set default calendar year and set state dataLoaded to true */
              calcDetail["calendar_year"] = feeScheduleData[0].calendar_year;

              /* initialise childDetails based on max children and add it to state variable */
              let childDetails = [];

              const defaultChildNames = [
                "First child",
                "Second child",
                "Third child",
                "Fourth child",
              ];

              for (let i = 0; i < calcDetail.max_children; i++) {
                const newChildNum = i + 1;
                const newChildDetail = {
                  child_name: defaultChildNames[i],
                  year_level: calcDetail[collectionName][0].key,
                  year_level_short_name:
                    calcDetail[collectionName][0].short_name,
                  form_element_id: "child_" + i,
                  tuition: feeScheduleData[0]["tuition_with_" + newChildNum],
                  levies: feeScheduleData[0].levies,
                  chromebook_levy: feeScheduleData[0].chromebook_levy,
                  per_day_rate: feeScheduleData[0].per_day_rate,
                };

                childDetails.push(newChildDetail);
              }

              calcDetail["child_details"] = childDetails;
              calcDetail = this.calculateFees(calcDetail);

              this.setState({ dataLoaded: true });
            } else {
              this.setState({ dataLoaded: false });
            }
          }
        );
      });

      calcDetail[feesCollection] = setStateData;

      counter += 1;

      this.setState({ calcDetail, dataLoaded: false });
    });
  };

  componentDidUpdate() {}

  async componentWillUnmount() {
    // remove listeners
    Object.keys(unSubSnapshot).forEach(function (key) {
      unSubSnapshot[key]();
    });
  }

  render() {
    const { dataLoaded, showResults } = this.state;
    let { calcDetail } = this.state;
    //console.log(calcDetail);
    const childDetails = calcDetail.child_details;
    childDetails.sort((a, b) => a.form_element_id > b.form_element_id);
    if (dataLoaded) {
      // this.calculateFees(calcDetail);
    }

    const calendarYearsList = calcDetail["fees"];
    const currentSelectedYear = calcDetail["calendar_year"];

    const totalTution = parseFloat(calcDetail.total_tuition);
    const totalLevies = parseFloat(calcDetail.total_levies);
    const totalChromebook = parseFloat(calcDetail.total_chromebook_levies);
    const totalPerDayRate = parseFloat(calcDetail.total_per_day_rate);

    const perTermTotalTuitionLevies =
      totalTution + totalLevies + totalChromebook;
    const perYearTotalTuitionLevies = perTermTotalTuitionLevies * 4;
    const per52WeekTuitionLevies = perYearTotalTuitionLevies / 52;

    return (
      <>
        {!dataLoaded ? (
          <div className="spinner-border text-success" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        ) : (
          <div className="home">
            <section>
              <div className="jumbotron jumbotron-fluid py-5 ">
                <div className="container text-center py-5 ">
                  {<img src={logo} className="small-logo" alt="Logo" />}
                  <h1 className="display-10">
                    Summerland Christian College <br /> Fees Estimator
                  </h1>
                  <div className="container">
                    {calendarYearsList.length > 0 ? (
                      <>
                        <form
                          className="mt-2 pt-3 px-5"
                          autoComplete="off"
                          onSubmit={this.handleSubmit}
                        >
                          <div className="d-flex">
                            <div className="form-group w-25 text-start">
                              {" "}
                              <label
                                className="form_label"
                                htmlFor="calendar_year"
                              >
                                Select Year
                              </label>
                              <select
                                className="form-control"
                                name="calendar_year"
                                onChange={this.handleItemDetailFormChange}
                                value={
                                  calcDetail.calendar_year
                                    ? calcDetail.calendar_year
                                    : "none"
                                }
                              >
                                {calendarYearsList.map((item, key) => {
                                  return (
                                    <option key={key} value={item.key}>
                                      {item.calendar_year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="form-group w-25 mx-5 text-start">
                              {" "}
                              <label
                                className="form_label"
                                htmlFor="number_of_children"
                              >
                                How many children?
                              </label>
                              <select
                                className="form-control"
                                placeholder="Number of children"
                                name="number_of_children"
                                onChange={this.handleItemDetailFormChange}
                                value={calcDetail.number_of_children || "0"}
                              >
                                {calcDetail.number_children_selector.map(
                                  (item, key) => {
                                    return (
                                      <option key={key} value={item.value}>
                                        {item.text}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </div>

                          <hr />

                          {childDetails && calcDetail.number_of_children > 0 ? (
                            <>
                              {Array.from(
                                { length: calcDetail.number_of_children },
                                (_, i) => (
                                  <div className="text-start" key={i}>
                                    {childDetails[i].child_name}

                                    <div className="d-flex">
                                      <div className="form-group w-25  text-start">
                                        {" "}
                                        <label
                                          className="form_label"
                                          htmlFor={"child_" + i}
                                        >
                                          Select Year Level:
                                        </label>
                                        <select
                                          className="form-control"
                                          placeholder="Year Level"
                                          name={"child_" + i}
                                          onChange={
                                            this.handleItemDetailFormChange
                                          }
                                          value={calcDetail["child_" + i] || ""}
                                        >
                                          {calcDetail[
                                            "fees_schedule_" +
                                              currentSelectedYear
                                          ].map((item, key) => {
                                            return (
                                              <option
                                                key={key}
                                                value={item.key}
                                              >
                                                {item.year_level}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      {showResults ? (
                                        <div className="form_label w-75 text-end">
                                          {calcDetail.child_details[i]
                                            .per_day_rate === "0.00" ? (
                                            <>
                                              <div>
                                                Tuition:{" $"}
                                                {
                                                  calcDetail.child_details[i]
                                                    .tuition
                                                }{" "}
                                                per term{" "}
                                              </div>

                                              <div>
                                                Levies:{" $"}
                                                {
                                                  calcDetail.child_details[i]
                                                    .levies
                                                }{" "}
                                                per term{" "}
                                              </div>

                                              {calcDetail.child_details[i]
                                                .chromebook_levy === "0.00" ? (
                                                ""
                                              ) : (
                                                <div>
                                                  Chromebook Levy:{" $"}
                                                  {
                                                    calcDetail.child_details[i]
                                                      .chromebook_levy
                                                  }{" "}
                                                  per term{" "}
                                                </div>
                                              )}
                                            </>
                                          ) : (
                                            <div>
                                              Per day rate:{" $"}
                                              {
                                                calcDetail.child_details[i]
                                                  .per_day_rate
                                              }{" "}
                                              per day
                                            </div>
                                          )}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <hr />
                                  </div>
                                )
                              )}
                              {showResults ? (
                                <>
                                  <h4 className="text-start">
                                    Total estimated fees for{" "}
                                    {calcDetail.calendar_year}:
                                  </h4>

                                  {!isNaN(per52WeekTuitionLevies) &&
                                  per52WeekTuitionLevies > "0" ? (
                                    <div className="d-flex text-start form_label">
                                      <div className="mr-2">
                                        Tutition and Levies:
                                      </div>
                                      <div className="mx-3">
                                        ${per52WeekTuitionLevies.toFixed(2)} per
                                        week <br />
                                        (52 weeks)
                                      </div>
                                      <div className="mx-3">
                                        ${perTermTotalTuitionLevies.toFixed(2)}{" "}
                                        per term
                                      </div>

                                      <div className="mx-3">
                                        ${perYearTotalTuitionLevies.toFixed(2)}{" "}
                                        per year
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {!isNaN(totalPerDayRate) &&
                                  totalPerDayRate > "0" ? (
                                    <div className="text-start form_label">
                                      Per day rate: $
                                      {totalPerDayRate.toFixed(2)}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <hr />
                                </>
                              ) : (
                                ""
                              )}

                              {!showResults ? (
                                <div className="m-5 text-start">
                                  <button
                                    className="btn btn-success mr-2"
                                    type="button"
                                    onClick={this.handleCalculateClick}
                                  >
                                    Calculate Now
                                  </button>
                                </div>
                              ) : (
                                <>
                                  <div className="d-flex form-group text-start">
                                    <label
                                      className="text-start py-1 mx-3"
                                      htmlFor="enrolment_contact"
                                    >
                                      <strong>
                                        Would you like contact from our
                                        enrollments specialist?
                                      </strong>
                                    </label>
                                    <select
                                      style={{ width: "5em" }}
                                      className="form-control text-start mr-auto"
                                      placeholder="Enrolment contact?"
                                      name="enrolment_contact"
                                      onChange={this.handleItemDetailFormChange}
                                      value={
                                        calcDetail.enrolment_contact || "0"
                                      }
                                    >
                                      <option value=""></option>
                                      <option value={true}>Yes</option>
                                    </select>
                                  </div>
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              <div className="text-start">
                                Families with 5 or more children are entitled to
                                special pricing.
                                <p>&nbsp;</p>
                              </div>
                              <div className="d-flex form-group text-start">
                                <label
                                  className="text-start py-1 mx-3"
                                  htmlFor="enrolment_contact"
                                >
                                  <strong>
                                    Would you like contact from our enrollments
                                    specialist?
                                  </strong>
                                </label>
                                <select
                                  style={{ width: "5em" }}
                                  className="form-control text-start mr-auto"
                                  placeholder="Enrolment contact?"
                                  name="enrolment_contact"
                                  onChange={this.handleItemDetailFormChange}
                                  value={calcDetail.enrolment_contact || "0"}
                                >
                                  <option value=""></option>
                                  <option value={true}>Yes</option>
                                </select>
                              </div>
                            </>
                          )}
                        </form>

                        {calcDetail.enrolment_contact ? (
                          <>
                            <ContactDetailsForm
                              src="Fees Estimator"
                              subject=""
                              extraData=""
                            />
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      "No fees available at this time."
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </>
    );
  }
}
