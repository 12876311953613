import React, { Component } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";

import { returnFeesYearTableDef } from "../../helpers/dataObjects";
import {
  AddNewYear,
  ExpandedFeeSchedule,
} from "../../helpers/globalHelperComponents";

class Fees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  handleAddNewYearButtonClick = () => {
    /*const newYear = lastYear * 1 + 1;
    const newYearData = {
      calendar_year: newYear.toString(),
      notes: "",
      status: "Un-published",
      version: 1,
    };
*/
    /* createUpdateDoc("/fees/", newYear, newYearData, true);  */
  };

  async componentDidMount() {}

  async componentWillUnmount() {}

  render() {
    const appData = this.props.appData;
    let filteredData = appData["fees"] ? appData["fees"] : [];

    const feesTableDef = returnFeesYearTableDef(appData);

    const ExpandedFeesScheduleComponent = ({ data }) => {
      return <ExpandedFeeSchedule data={data} appData={appData} />;
    };

    return (
      <div className="main-section">
        <AddNewYear />
        <DataTable
          responsive
          highlightOnHover
          fixedHeaer
          expandableRows
          expandOnRowClicked
          expandableRowsComponent={ExpandedFeesScheduleComponent}
          columns={feesTableDef.columns}
          data={filteredData}
          defaultSortFieldId={feesTableDef.defaultSortColumn}
          theme="crm"
        />
      </div>
    );
  }
}
export default Fees;
