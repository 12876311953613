import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUsers,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faClipboardCheck,
  faHandsHelping,
  faMoneyBillWave,
  faUserTag,
  faCalendarCheck,
  faQuestion,
  faStar,
  faCog,
  faMap,
  faLink,
  faAddressBook,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";

import {
  ProSidebar,
  SidebarHeader,
  SidebarContent,
  SubMenu,
  Menu,
  MenuItem,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = { windowWidth: window.innerWidth };
  }

  handleResize = (e) => {
    let sideBarIsCollapsed = this.state.sideBarIsCollapsed;
    sideBarIsCollapsed = window.innerWidth > 768 ? false : true;
    this.setState({ windowWidth: window.innerWidth, sideBarIsCollapsed });
  };

  handleSidebarHeaderClick = () => {
    const sideBarIsCollapsed = !this.state.sideBarIsCollapsed;
    this.setState({ sideBarIsCollapsed });
  };

  handleSwitchComponent = (selectedComponent) => {
    this.setState({ selectedOption: "" });

    this.props.onSwitchComponent(selectedComponent);

    this.setState({ selectedOption: selectedComponent });
  };

  async componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const selectedOption = this.state.selectedOption;
    // const permisssionLevels = this.props.permisssionLevels;

    let isSuperAdmin = false;
    let isSettingsAdmin = false;
    let isUsersAdmin = false;

    if (this.props.usersList && this.props.currentUserDetails) {
      const currentUserExpandedDetails = this.props.usersList.filter(
        (user) => user.key === this.props.currentUserDetails.uid
      );

      isSuperAdmin = currentUserExpandedDetails[0].permission_groups.includes(
        "EaI4KkefSGMQVhrdI09q"
      );
      isSettingsAdmin =
        currentUserExpandedDetails[0].permission_groups.includes(
          "gzN7RWqaDNGgwm0lGVHy"
        );
      isUsersAdmin = currentUserExpandedDetails[0].permission_groups.includes(
        "DZzAGgNbWi8ECua9M6Z4"
      );
    }

    return (
      <div
        className="ml-auto"
        style={{ zIndex: "0", minHeight: "calc(100vh - 90px)" }}
      >
        <ProSidebar className="" collapsed={this.state.sideBarIsCollapsed}>
          <SidebarHeader
            className="text-center sidebar-header-arrow"
            onClick={this.handleSidebarHeaderClick}
          >
            {this.state.sideBarIsCollapsed ? (
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            ) : (
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            )}
          </SidebarHeader>
          <SidebarContent className="">
            <Menu popperArrow="true" iconShape="circle">
              <MenuItem
                className={
                  selectedOption === "dashboard-summary" ? "selectedOption" : ""
                }
                icon={<FontAwesomeIcon icon={faHome} />}
                onClick={() => this.handleSwitchComponent("dashboard-summary")}
              >
                Dashboard
              </MenuItem>

              {isSettingsAdmin || isSuperAdmin ? (
                <>
                  <MenuItem
                    className={
                      selectedOption === "contacts" ? "selectedOption" : ""
                    }
                    icon={<FontAwesomeIcon icon={faAddressBook} />}
                    onClick={() => this.handleSwitchComponent("contacts")}
                  >
                    Contacts
                  </MenuItem>

                  <MenuItem
                    className={
                      selectedOption === "fees" ? "selectedOption" : ""
                    }
                    icon={<FontAwesomeIcon icon={faMoneyBillWave} />}
                    onClick={() => this.handleSwitchComponent("fees")}
                  >
                    Fees
                  </MenuItem>

                  <MenuItem
                    className={
                      selectedOption === "help" ? "selectedOption" : ""
                    }
                    icon={<FontAwesomeIcon icon={faQuestion} />}
                    onClick={() => this.handleSwitchComponent("help")}
                  >
                    Help
                  </MenuItem>
                </>
              ) : (
                ""
              )}

              {isUsersAdmin || isSuperAdmin ? (
                <>
                  <SubMenu
                    title="Users"
                    icon={<FontAwesomeIcon icon={faUserTag} />}
                  >
                    <MenuItem
                      className={
                        selectedOption === "users" ? "selectedOption" : ""
                      }
                      icon={<FontAwesomeIcon icon={faUserTag} />}
                      onClick={() => this.handleSwitchComponent("users")}
                    >
                      Users
                    </MenuItem>
                  </SubMenu>
                </>
              ) : (
                ""
              )}
            </Menu>
          </SidebarContent>
        </ProSidebar>
      </div>
    );
  }
}

export default Sidebar;
