import React, { Component } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import SlidingPane from "react-sliding-pane";

import { createDocCustomID } from "../helpers/db";
import { confirmAlert } from "react-confirm-alert";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faTrash } from "@fortawesome/free-solid-svg-icons";
import {
  auth,
  db,
  onSnapshot,
  query,
  collection,
  orderBy,
} from "../services/firebase";

import { returnFeeScheduleTableDef } from "../helpers/dataObjects";
import FeeScheduleItemForm from "../components/forms/FeeScheduleItemForm";

let unSubSnapshot = {};

export class CheckList extends React.Component {
  render() {
    const { isChecked, data, handleCheck } = this.props;
    return (
      <div>
        <input
          checked={isChecked}
          type="checkbox"
          onChange={() => handleCheck(data)}
        />
      </div>
    );
  }
}

export class AddNewYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addButtonVisible: true,
      addFormVisible: false,
      calendarYear: "",
    };
  }

  handleAddNewYearButtonClick = () => {
    this.setState({ addButtonVisible: false, addFormVisible: true });
  };

  handleCloseForm = () => {
    this.setState({ addButtonVisible: true, addFormVisible: false });
  };

  handleItemSaveButton = () => {
    const newYearData = {
      calendar_year: this.state.calendarYear,
      notes: "",
      published: false,
      version: 1,
    };

    createDocCustomID("fees", this.state.calendarYear, newYearData);
    this.setState({ addButtonVisible: true, addFormVisible: false });
  };

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let calendarYear = { ...this.state.calendarYear };
    calendarYear = value;

    this.setState({ calendarYear });
  };

  render() {
    const { isChecked, data, handleCheck } = this.props;
    const { addButtonVisible, addFormVisible, calendarYear } = this.state;
    return (
      <>
        {addButtonVisible ? (
          <div>
            <div
              onClick={this.handleAddNewYearButtonClick}
              className="small btn btn-success mt-4 mx-2"
            >
              Add a New Year
            </div>
          </div>
        ) : null}

        {addFormVisible ? (
          <div>
            <form className="mt-1 py-3 px-3" onSubmit={this.handleSubmit}>
              <div className="d-flex">
                <div className="form-group w-5">
                  <label className="form_label" htmlFor="calendar_year">
                    Enter Year
                  </label>
                  <input
                    className="form-control w-5"
                    placeholder="YYYY"
                    name="calendar_year"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={calendarYear || ""}
                  ></input>
                </div>

                <div
                  onClick={this.handleItemSaveButton}
                  className="small btn btn-success mt-4 mx-2"
                >
                  Add Year
                </div>
                <div
                  onClick={this.handleCloseForm}
                  className="small btn btn-danger mt-4 mx-1"
                >
                  Cancel
                </div>
              </div>
            </form>
          </div>
        ) : null}
      </>
    );
  }
}

export class DeleteRowButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDeleteRow = () => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this entry?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.onDeleteButtonClicked();
          },
        },
        {
          label: "No",
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const { row, collectionPath, deleteNoRecycle } = this.props;

    return (
      <>
        <div className="btn" onClick={this.handleDeleteRow}>
          <FontAwesomeIcon icon={faTrash} />
        </div>
      </>
    );
  }
}

export class CopyRowButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleCopyRow = () => {
    confirmAlert({
      title: "Confirm copy",
      message: this.props.confirmMessage,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.props.onCopyButtonClicked();
          },
        },
        {
          label: "No",
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const { row, collectionPath, deleteNoRecycle } = this.props;

    return (
      <>
        <div className="btn" onClick={this.handleCopyRow}>
          <FontAwesomeIcon icon={faCopy} />
        </div>
      </>
    );
  }
}

export class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { row, isOn, onToggle, onColor } = this.props;
    return (
      <>
        <input
          checked={isOn}
          onChange={onToggle}
          className="react-switch-checkbox"
          id={"react-switch-new"}
          type="checkbox"
        />
        <label
          style={{ background: isOn && onColor }}
          className="react-switch-label"
          htmlFor={"react-switch-new"}
        >
          <span className={"react-switch-button"} />
        </label>
      </>
    );
  }
}

export class ExpandedFeeSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentData: {},
      isSlidingPaneOpen: false,
      slidingPaneTitle: "Fee Schedule Details",
      calendarYear: "",
      selectedItemDetail: {},
      isNew: false,
    };
  }

  async componentDidMount() {
    this.setState({ isDataLoadCompleted: false, readError: false });

    const currentKey = this.props.data.key;

    try {
      const collectionName = "fees_schedule_" + currentKey;
      const collectionPath = "fees/" + currentKey + "/fee_schedule/";

      const collectionQuery = query(
        collection(db, collectionPath),
        orderBy("short_name")
      );

      unSubSnapshot[collectionName] = onSnapshot(
        collectionQuery,
        (collSnapshot) => {
          let setStateData = [];

          let componentData = this.state.componentData;

          collSnapshot.forEach((doc) => {
            let snapshotData = [];
            snapshotData = doc.data();
            snapshotData["key"] = doc.id;
            setStateData.push(snapshotData);
          });

          componentData[collectionName] = setStateData;

          const calendarYear = currentKey;

          this.setState({ componentData, calendarYear });
        }
      );
    } catch (error) {
      this.setState({ isDataLoadCompleted: true, readError: error.message });
      console.log(error);
    }
  }

  async componentWillUnmount() {
    // remove listeners
    Object.keys(unSubSnapshot).forEach(function (key) {
      unSubSnapshot[key]();
    });
  }

  handleItemSaveDeleteButtonClick = () => {
    this.setState({ isSlidingPaneOpen: false });
  };

  render() {
    const { data } = this.props;
    const componentData = this.state.componentData;
    const calendarYear = this.state.calendarYear;

    const filteredComponentData = componentData["fees_schedule_" + data.key];

    const feeScheduleTableDef = returnFeeScheduleTableDef();

    const selectedItemDetail = this.state.selectedItemDetail;

    const handleRowClicked = (row) => {
      this.setState({
        selectedItemDetail: row,
        isNew: false,
        isSlidingPaneOpen: true,
      });
    };

    return (
      <>
        <strong>Fee Schedule - {data.key}</strong>
        <AddNewFeeScheduleEntry calendarYear={data.key} />
        <DataTable
          responsive
          highlightOnHover
          fixedHeaer
          columns={feeScheduleTableDef.columns}
          data={filteredComponentData}
          defaultSortFieldId={feeScheduleTableDef.defaultSortColumn}
          onRowClicked={handleRowClicked}
          theme="crm"
        />
        <SlidingPane
          className="sliding-pane"
          overlayClassName="pane-details-sliding-pane"
          isOpen={this.state.isSlidingPaneOpen}
          title={this.state.slidingPaneTitle}
          subtitle=""
          width="85%"
          from="right"
          onRequestClose={() => {
            this.setState({ isSlidingPaneOpen: false });
          }}
        >
          <FeeScheduleItemForm
            isNew={this.state.isNew}
            selectedItemDetail={selectedItemDetail}
            calendarYear={calendarYear}
            onItemSaveDeleteButtonClicked={this.handleItemSaveDeleteButtonClick}
          />
        </SlidingPane>
      </>
    );
  }
}

export class AddNewFeeScheduleEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSlidingPaneOpen: false,
      slidingPaneTitle: "Fee Schedule Details",
    };
  }

  handleAddNewEntryButtonClick = () => {
    this.setState({ isNew: true, isSlidingPaneOpen: true });
  };

  handleItemSaveDeleteButtonClick = () => {
    this.setState({ isSlidingPaneOpen: false });
  };

  render() {
    const { isChecked, data, handleCheck, calendarYear } = this.props;
    const { addButtonVisible, addFormVisible } = this.state;
    return (
      <>
        <div>
          <div
            onClick={this.handleAddNewEntryButtonClick}
            className="small btn btn-success mt-4 mx-2"
          >
            Add a Fee Schedule Entry
          </div>
        </div>
        <SlidingPane
          className="sliding-pane"
          overlayClassName="pane-details-sliding-pane"
          isOpen={this.state.isSlidingPaneOpen}
          title={this.state.slidingPaneTitle}
          subtitle=""
          width="85%"
          from="right"
          onRequestClose={() => {
            this.setState({ isSlidingPaneOpen: false });
          }}
        >
          <FeeScheduleItemForm
            isNew={this.state.isNew}
            selectedItemDetail={this.state.selectedItemDetail}
            calendarYear={calendarYear}
            onItemSaveDeleteButtonClicked={this.handleItemSaveDeleteButtonClick}
          />
        </SlidingPane>
      </>
    );
  }
}
