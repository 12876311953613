import React, { Component } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";

import { auth, onAuthStateChanged } from "./services/firebase";

import { createTheme } from "react-data-table-component";

import "./styles.css";

import "react-sliding-pane/dist/react-sliding-pane.css";

import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import ProfileDetails from "./pages/ProfileDetails";
import FeesCalc from "./pages/public/FeesCalc";

//const firestoreTimeStamp = getFirestoreTimestamp();

createTheme("crm", {
  background: {
    default: "rgba(255,255,255,0.5)",
  },
});

function PrivateRoute({ component: Component, authenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
  let locationPath = useLocation();

  let locationPathname = "/dashboard";
  if (locationPath.state) {
    locationPathname = locationPath.state.from.pathname;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === false ? (
          <Component {...props} />
        ) : (
          <Redirect to={locationPathname} />
        )
      }
    />
  );
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      siteTitle: "Summerland Christian College CRM",
      authenticated: false,
      loading: true,
      loadingModule: "App",
      currentUserDetails: {},
      dashboardData: {},
    };
  }

  async componentDidMount() {
    const pathArray = window.location.pathname.split("/");

    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.setState({
          authenticated: true,
          loading: false,
        });
      } else {
        this.setState({ authenticated: false, loading: false });
      }
    });
  }

  render() {
    return this.state.loading === true ? (
      <React.Fragment>
        <div
          style={{ textAlign: "center", marginLeft: "30px" }}
          className="loading-text"
        >
          Life is change. Growth is optional. Choose wisely. <br />
          <em>Karen Clarke</em>
        </div>
        <div
          className="spinner-border text-success loading-indicator"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </React.Fragment>
    ) : (
      <Router>
        <Switch>
          <PublicRoute
            exact
            path="/"
            authenticated={this.state.authenticated}
            component={Home}
          />

          <PublicRoute
            exact
            path="/fees-calc"
            authenticated={this.state.authenticated}
            component={FeesCalc}
          />

          <PrivateRoute
            exact
            path="/profile"
            authenticated={this.state.authenticated}
            component={ProfileDetails}
          />

          <PrivateRoute
            path="/dashboard"
            authenticated={this.state.authenticated}
            component={Dashboard}
          />
        </Switch>
      </Router>
    );
  }
}

export default App;
