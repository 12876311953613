import React, { Component } from "react";

import {
  db,
  collection,
  addDoc,
  getServerTimeNow,
} from "../../services/firebase";

export default class ContactDetailsForm extends Component {
  constructor() {
    super();
    this.state = {
      error: null,
      formErrors: {},
      contactDetail: {},
      displayOptions: {
        displayLastName: true,
        displayPhoneNumber: true,
        displayEmailAddress: true,
        displayStreetAddress: true,
        displaySuburb: true,
        displayState: true,
        displayPostcode: true,
        displayMessage: true,
      },
      displayResponse: false,
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: "" });
  };

  handleItemDetailFormChange = async (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let contactDetail = { ...this.state.contactDetail };

    if (target.type === "checkbox") {
      contactDetail[name] = target.checked;
    } else {
      contactDetail[name] = value;
    }

    this.setState({ contactDetail });
  };

  componentDidMount = async () => {
    let { displayOptions } = this.state;

    if (this.props.src === "Fees Estimator") {
      displayOptions = {
        displayLastName: true,
        displayPhoneNumber: true,
        displayEmailAddress: true,
        displayStreetAddress: false,
        displaySuburb: true,
        displayState: false,
        displayPostcode: true,
        displayCommentsBox: true,
        displayBestContactTime: true,
      };
    }
    this.setState({ displayOptions });
  };

  componentDidUpdate = () => {};

  componentWillUnmount = () => {};

  handleValidation = () => {
    let formIsValid = true;
    let formErrors = {};

    let dataObj = this.state.contactDetail;

    /* validation - required fields, correct formats, etc */
    if (!dataObj.first_name) {
      formErrors["first_name"] = "Required";
      formIsValid = false;
    }

    if (!dataObj.email_address) {
      formErrors["email_address"] = "Required";
      formIsValid = false;
    }

    if (!dataObj.phone_number) {
      formErrors["phone_number"] = "Required";
      formIsValid = false;
    }

    this.setState({ formErrors });
    return formIsValid;
  };

  handleItemSaveButton = async () => {
    const currentFirestoreTimeStamp = await getServerTimeNow();
    const collectionPath = "contacts";

    let dataObj = this.state.contactDetail;

    if (this.handleValidation()) {
      console.log("form is valid...");
      /* add datetime stamps */
      dataObj["created"] = currentFirestoreTimeStamp;
      dataObj["created_by"] = "external user";
      dataObj["updated"] = currentFirestoreTimeStamp;
      dataObj["updated_by"] = "external user";

      console.log(dataObj);
      /* write to database */
      await addDoc(collection(db, collectionPath), dataObj);

      /* clear contactDetail object, hide form and display thank you response */
      this.setState({ displayResponse: true });
    }
  };

  render() {
    const { contactDetail, displayOptions, displayResponse } = this.state;

    return (
      <>
        {!displayResponse ? (
          <form
            id="calc_form"
            className="mt-2 pt-3 px-5 text-start"
            onSubmit={this.handleSubmit}
          >
            <div className="d-flex">
              <div className="form-group w-50 mx-2">
                <label className="form_label" htmlFor="first_name">
                  First Name
                </label>
                <input
                  className="form-control"
                  placeholder="First Name"
                  name="first_name"
                  type="text"
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.first_name || ""}
                ></input>
                <span className="form_label" style={{ color: "red" }}>
                  {this.state.formErrors["first_name"]}
                </span>
              </div>
              {displayOptions.displayLastName ? (
                <div className="form-group w-50 mx-2">
                  <label className="form_label" htmlFor="last_name">
                    Last Name
                  </label>
                  <input
                    className="form-control"
                    placeholder="Last Name"
                    name="last_name"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.last_name || ""}
                  ></input>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="d-flex">
              {displayOptions.displayPhoneNumber ? (
                <div className="form-group w-25 mx-2">
                  <label className="form_label" htmlFor="phone_number">
                    Phone Number
                  </label>
                  <input
                    className="form-control"
                    placeholder="Phone Number"
                    name="phone_number"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.phone_number || ""}
                  ></input>
                  <span className="form_label" style={{ color: "red" }}>
                    {this.state.formErrors["phone_number"]}
                  </span>
                </div>
              ) : (
                ""
              )}
              {displayOptions.displayEmailAddress ? (
                <div className="form-group w-75 mx-2">
                  <label className="form_label" htmlFor="email_address">
                    Email Address
                  </label>
                  <input
                    className="form-control"
                    placeholder="Email Address"
                    name="email_address"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.email_address || ""}
                  ></input>
                  <span className="form_label" style={{ color: "red" }}>
                    {this.state.formErrors["email_address"]}
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
            {displayOptions.displayStreetAddress ? (
              <div className="form-group w-50 mx-2">
                <label className="form_label" htmlFor="street_address">
                  Street Address
                </label>
                <textarea
                  className="form-control"
                  placeholder="Street Address"
                  name="street_address"
                  rows="2"
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.street_address || ""}
                ></textarea>
              </div>
            ) : (
              ""
            )}

            <div className="d-flex">
              {displayOptions.displaySuburb ? (
                <div className="form-group w-50 mx-2">
                  <label className="form_label" htmlFor="suburb">
                    Suburb
                  </label>

                  <input
                    className="form-control"
                    placeholder="Suburb"
                    name="suburb"
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.suburb || ""}
                  ></input>
                </div>
              ) : (
                ""
              )}

              {displayOptions.displayState ? (
                <div className="form-group w-25 mx-2">
                  <label className="form_label" htmlFor="state">
                    State
                  </label>
                  <input
                    className="form-control"
                    placeholder="State"
                    name="state"
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.state || ""}
                  ></input>
                </div>
              ) : (
                ""
              )}

              {displayOptions.displayPostcode ? (
                <div className="form-group w-25 mx-2">
                  <label className="form_label" htmlFor="postcode">
                    Postcode
                  </label>
                  <input
                    className="form-control"
                    placeholder="Postcode"
                    name="postcode"
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.postcode || ""}
                  ></input>
                </div>
              ) : (
                ""
              )}
            </div>
            {displayOptions.displayCommentsBox ? (
              <div className="form-group w-50 mx-2">
                <label className="form_label" htmlFor="notes">
                  Comments / Questions
                </label>
                <textarea
                  className="form-control"
                  placeholder="Comments / Questions"
                  name="notes"
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.notes || ""}
                ></textarea>
              </div>
            ) : (
              ""
            )}

            {displayOptions.displayBestContactTime ? (
              <div className="form-group w-50 mx-2">
                <label className="form_label" htmlFor="preferred_contact">
                  Best time for contact?
                </label>
                <select
                  className="form-control"
                  placeholder="Best time to contact?"
                  name="contact_time"
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.contact_time || "0"}
                >
                  <option value="8AM-4PM">Anytime (8am - 4pm)</option>
                  <option value="8AM-12PM">Morning (8am - 12pm)</option>
                  <option value="12PM-4PM">Afternoon (12pm - 4pm)</option>
                </select>
              </div>
            ) : (
              ""
            )}

            <div className="form-group">
              {this.state.error ? (
                <p className="text-danger">{this.state.error}</p>
              ) : null}
            </div>

            <div
              onClick={this.handleItemSaveButton}
              className="small btn btn-success m-3"
            >
              Send Contact Details
            </div>
          </form>
        ) : (
          <div className="m-3">
            Thank you for your contact details. You will receive a notification
            of receipt of your information and one of our specialists will be in
            contact with your shortly!
          </div>
        )}
        <div className="form_label text-start mx-3">
          <i>
            All information provided will be stored safely according to the
            privacy policy.
          </i>
        </div>
      </>
    );
  }
}
