import React, { Component } from 'react';

class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  async componentWillUnmount() {}

  render() {
    return <div>Help page</div>;
  }
}
export default Help;
