import React, { Component } from "react";
import { Link } from "react-router-dom";

import { currentAuthUser } from "../../helpers/db";
import { logout } from "../../services/firebase";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {}

  render() {
    return (
      <footer className="">
        <div className="pt-5 container text-center mt-auto">
          <p>
            &copy; Brilliant Success Biz {new Date().getFullYear()}.<br />
            <span className="footer-version">v20211101.1430</span>
            <br />
            {currentAuthUser() ? (
              <span className="small">
                Signed as {currentAuthUser().email} |{" "}
                <Link to="/" onClick={() => logout()}>
                  Logout
                </Link>
              </span>
            ) : (
              ""
            )}
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
