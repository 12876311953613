import React, { Component } from "react";

//Import dayjs for date and time
import dayjs from "dayjs";

import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { newFeeScheduleDetail } from "../../helpers/dataObjects";
import { createUpdateDoc, recycleDoc } from "../../helpers/db";

export default class FeeScheduleItemForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetailForm: false,
      itemDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: "" });
  };

  async componentWillUnmount() {
    let itemDetail = this.state.itemDetail;
    itemDetail["calendar_year"] = this.props.calendarYear;
    if (itemDetail.year_level.length > 1 && itemDetail.short_name.length > 1) {
      createUpdateDoc(
        "fees/" + this.props.calendarYear + "/fee_schedule/",
        itemDetail.key,
        itemDetail,
        this.props.isNew
      );
    }

    if (itemDetail.deleted) {
      recycleDoc("contacts", itemDetail.key);
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingDetailForm: true });

    console.log(this.props.selectedItemDetail);

    if (this.props.isNew) {
      this.setState({ itemDetail: newFeeScheduleDetail });
    } else {
      this.setState({ itemDetail: this.props.selectedItemDetail });
    }

    this.setState({ loadingDetailForm: false });
  }

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let itemDetail = { ...this.state.itemDetail };

    if (target.type === "checkbox") {
      itemDetail[name] = target.checked;
    } else {
      itemDetail[name] = value;
    }
    this.setState({ itemDetail });
  };

  handleItemSaveButton = () => {
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemDeleteButton = () => {
    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to delete this entry?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            let itemDetail = { ...this.state.itemDetail };
            itemDetail["deleted"] = true;
            itemDetail["purge_date"] = dayjs()
              .add(90, "day")
              .format("YYYY-MM-DD");
            this.setState({ itemDetail });
            this.props.onItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: "No",
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const itemDetail = this.state.itemDetail;

    return (
      <div>
        <div className="container" ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingDetailForm ? (
            <div className="spinner-border text-success" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            ""
          )}

          <React.Fragment>
            <div className="d-flex">
              <div className="d-flex small w-50">
                Edit details below. Close out of this panel when finished and
                your changes will be saved automatically.
              </div>
            </div>
            <form className="mt-1 py-3 px-3" onSubmit={this.handleSubmit}>
              <div className="d-flex">
                <div className="form-group">
                  <label className="form_label" htmlFor="year_level">
                    Year Level
                  </label>
                  <input
                    className="form-control"
                    placeholder="Year Level"
                    name="year_level"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.year_level || ""}
                  ></input>
                </div>

                <div className="form-group">
                  <label className="form_label" htmlFor="short_name">
                    Year Level Short Name
                  </label>
                  <input
                    className="form-control"
                    placeholder="Year Level Short Name"
                    name="short_name"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.short_name || ""}
                  ></input>
                </div>
              </div>
              <div className="d-flex">
                <div className="form-group w-25">
                  <label className="form_label" htmlFor="tuition_with_1">
                    Tuition - 1 Child
                  </label>
                  <input
                    className="form-control"
                    placeholder="Tuition - 1 Child"
                    name="tuition_with_1"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.tuition_with_1 || ""}
                  ></input>
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group w-25">
                  <label className="form_label" htmlFor="tuition_with_2">
                    Tuition - 2 Children
                  </label>
                  <input
                    className="form-control"
                    placeholder="Tuition - 2 Children"
                    name="tuition_with_2"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.tuition_with_2 || ""}
                  ></input>
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group w-25">
                  <label className="form_label" htmlFor="tuition_with_3">
                    Tuition - 3 Children
                  </label>
                  <input
                    className="form-control"
                    placeholder="Tuition - 3 Children"
                    name="tuition_with_3"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.tuition_with_3 || ""}
                  ></input>
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group w-25">
                  <label className="form_label" htmlFor="tuition_with_4">
                    Tuition - 4 Children
                  </label>
                  <input
                    className="form-control"
                    placeholder="Tuition - 4 Children"
                    name="tuition_with_4"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.tuition_with_4 || ""}
                  ></input>
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group w-25">
                  <label className="form_label" htmlFor="levies">
                    Levies (per child)
                  </label>
                  <input
                    className="form-control"
                    placeholder="Levies"
                    name="levies"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.levies || ""}
                  ></input>
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group w-25">
                  <label className="form_label" htmlFor="chromebook_levy">
                    Chromebook Levy
                  </label>
                  <input
                    className="form-control"
                    placeholder="Chromebook Levy"
                    name="chromebook_levy"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.chromebook_levy || ""}
                  ></input>
                </div>
              </div>

              <div className="d-flex">
                <div className="form-group w-25">
                  <label className="form_label" htmlFor="per_day_rate">
                    Per Day Rate
                  </label>
                  <input
                    className="form-control"
                    placeholder="Per Day Rate"
                    name="per_day_rate"
                    type="text"
                    onChange={this.handleItemDetailFormChange}
                    value={itemDetail.per_day_rate || ""}
                  ></input>
                </div>
              </div>

              <div className="form-group">
                {this.state.error ? (
                  <p className="text-danger">{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleItemSaveButton}
              className="small btn btn-success mx-5"
            >
              Save Changes
            </div>
            {!this.props.isNew ? (
              <div
                onClick={this.handleItemDeleteButton}
                className="small btn btn-danger"
              >
                Delete this Contact
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
