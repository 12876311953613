import React, { Component } from 'react';

//Import dayjs for date and time
import dayjs from 'dayjs';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { newContactDetail } from '../../helpers/dataObjects';
import { createUpdateDoc, recycleDoc } from '../../helpers/db';

export default class ContactDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingDetailForm: false,
      contactDetail: [],
    };
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ error: '' });
  };

  async componentWillUnmount() {
    let contactDetail = this.state.contactDetail;
    if (
      contactDetail.first_name.length > 1 &&
      contactDetail.last_name.length > 1
    ) {
      createUpdateDoc(
        'contacts',
        contactDetail.key,
        contactDetail,
        this.props.isNew
      );
    }

    if (contactDetail.deleted) {
      recycleDoc('contacts', contactDetail.key);
    }
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingDetailForm: true });

    if (this.props.isNew) {
      this.setState({ contactDetail: newContactDetail });
    } else {
      this.setState({ contactDetail: this.props.selectedItemDetail });
    }

    this.setState({ loadingDetailForm: false });
  }

  handleItemDetailFormChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    let contactDetail = { ...this.state.contactDetail };

    if (target.type === 'checkbox') {
      contactDetail[name] = target.checked;
    } else {
      contactDetail[name] = value;
    }
    this.setState({ contactDetail });
  };

  handleItemSaveButton = () => {
    this.props.onItemSaveDeleteButtonClicked(true);
  };

  handleItemDeleteButton = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete this contact?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            let contactDetail = { ...this.state.contactDetail };
            contactDetail['deleted'] = true;
            contactDetail['purge_date'] = dayjs()
              .add(90, 'day')
              .format('YYYY-MM-DD');
            this.setState({ contactDetail });
            this.props.onItemSaveDeleteButtonClicked(true);
          },
        },
        {
          label: 'No',
          onClick: () => {
            // do nothing
          },
        },
      ],
    });
  };

  render() {
    const contactDetail = this.state.contactDetail;
    const leadersList = this.props.leadersList;

    const photoConsentDateVisible =
      contactDetail.photo_consent_status === 'true' ||
      contactDetail.photo_consent_status === true
        ? 'display-div'
        : 'hidden-div';

    return (
      <div>
        <div className='container' ref={this.myRef}>
          {/* loading indicator */}
          {this.state.loadingDetailForm ? (
            <div className='spinner-border text-success' role='status'>
              <span className='sr-only'>Loading...</span>
            </div>
          ) : (
            ''
          )}

          <React.Fragment>
            <div className='d-flex'>
              <div className='d-flex small w-50'>
                Edit details below. Close out of this panel when finished as
                your changes will be saved automatically.
              </div>
            </div>
            <form className='mt-1 py-3 px-3' onSubmit={this.handleSubmit}>
              <div className='d-flex'>
                <div className='form-group'>
                  <label className='form_label' htmlFor='first_name'>
                    First Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='First Name'
                    name='first_name'
                    type='text'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.first_name || ''}></input>
                </div>

                <div className='form-group'>
                  <label className='form_label' htmlFor='last_name'>
                    Last Name
                  </label>
                  <input
                    className='form-control'
                    placeholder='Last Name'
                    name='last_name'
                    type='text'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.last_name || ''}></input>
                </div>
              </div>
              <div className='d-flex'>
                <div className='form-group w-25'>
                  <label className='form_label' htmlFor='phone_number'>
                    Phone Number
                  </label>
                  <input
                    className='form-control'
                    placeholder='Phone Number'
                    name='phone_number'
                    type='text'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.phone_number || ''}></input>
                </div>

                <div className='form-group w-25'>
                  {' '}
                  <label className='form_label' htmlFor='sms_reminders'>
                    SMS Reminders?
                  </label>
                  <select
                    className='form-control'
                    placeholder='SMS Reminders'
                    name='sms_reminders'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.sms_reminders || '0'}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </div>
              </div>

              <div className='form-group'>
                <label className='form_label' htmlFor='email_address'>
                  Email Address
                </label>
                <input
                  className='form-control'
                  placeholder='Email Address'
                  name='email_address'
                  type='text'
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.email_address || ''}></input>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='street_address'>
                  Street Address
                </label>
                <textarea
                  className='form-control'
                  placeholder='Street Address'
                  name='street_address'
                  rows='2'
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.street_address || ''}></textarea>
              </div>
              <div className='d-flex'>
                <div className='form-group w-35'>
                  <label className='form_label' htmlFor='suburb'>
                    Suburb
                  </label>

                  <input
                    className='form-control'
                    placeholder='Suburb'
                    name='suburb'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.suburb || ''}></input>
                </div>{' '}
                <div className='form-group w-15'>
                  <label className='form_label' htmlFor='state'>
                    State
                  </label>
                  <input
                    className='form-control'
                    placeholder='State'
                    name='state'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.state || ''}></input>
                </div>{' '}
                <div className='form-group w-15'>
                  <label className='form_label' htmlFor='postcode'>
                    Postcode
                  </label>
                  <input
                    className='form-control'
                    placeholder='Postcode'
                    name='postcode'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.postcode || ''}></input>
                </div>
              </div>
              <div className='d-flex'>
                <div className='form-group w-25'>
                  {' '}
                  <label className='form_label' htmlFor='on_facebook'>
                    On Facebook?
                  </label>
                  <select
                    className='form-control'
                    placeholder='On Facebook'
                    name='on_facebook'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.on_facebook || '0'}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </div>
                <div className='form-group w-25'>
                  {' '}
                  <label className='form_label' htmlFor='church_attendee'>
                    Church Attendee?
                  </label>
                  <select
                    className='form-control'
                    placeholder='Church Attendee'
                    name='church_attendee'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.church_attendee || '0'}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </div>
                <div className='form-group w-25'>
                  {' '}
                  <label
                    className='form_label'
                    htmlFor='receive_church_information'>
                    Wants Church Information?
                  </label>
                  <select
                    className='form-control'
                    placeholder='Wants Church Information?'
                    name='receive_church_information'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.receive_church_information || '0'}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </div>
              </div>

              <div className='d-flex'>
                <div className='form-group w-50'>
                  <label className='form_label' htmlFor='children_details'>
                    Children Details
                  </label>
                  <textarea
                    className='form-control'
                    placeholder='Children Details'
                    name='children_details'
                    rows='3'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.children_details || ''}></textarea>
                </div>

                <div className='form-group w-25'>
                  <label className='form_label' htmlFor='partner_name'>
                    Partner Name or No Partner
                  </label>
                  <input
                    className='form-control'
                    placeholder='Partner Name'
                    name='partner_name'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.partner_name || ''}></input>
                </div>
              </div>

              <div className='d-flex'>
                <div className='form-group w-20'>
                  {' '}
                  <label className='form_label' htmlFor='photo_consent_status'>
                    Photo Consent
                  </label>
                  <select
                    className='form-control'
                    placeholder='Photo Consent'
                    name='photo_consent_status'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.photo_consent_status || false}>
                    <option value={false}>No</option>
                    <option value={true}>Yes</option>
                  </select>
                </div>

                <div
                  className={'form-group ml-5 w-25 ' + photoConsentDateVisible}>
                  <label className='form_label' htmlFor='photo_consent_date'>
                    Date
                  </label>
                  <input
                    className='form-control'
                    placeholder='Photo Consent Date'
                    name='photo_consent_date'
                    type='date'
                    onChange={this.handleItemDetailFormChange}
                    value={contactDetail.photo_consent_date || ''}></input>
                </div>
              </div>
              <div className='form-group'>
                <label className='form_label' htmlFor='notes'>
                  Notes
                </label>
                <textarea
                  className='form-control'
                  placeholder='Notes'
                  name='notes'
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.notes || ''}></textarea>
              </div>

              <div className='form-group w-50'>
                {' '}
                <label className='form_label' htmlFor='care_leader'>
                  Care Leader
                </label>
                <select
                  className='form-control'
                  name='care_leader'
                  onChange={this.handleItemDetailFormChange}
                  value={
                    contactDetail.care_leader
                      ? contactDetail.care_leader
                      : 'none'
                  }>
                  <option value='none'>No Care Leader Assigned</option>
                  {leadersList.map((item, key) => {
                    return (
                      <option key={key} value={item.key}>
                        {item.display_name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='status'>
                  Status
                </label>
                <select
                  className='form-control'
                  placeholder='Status'
                  name='status'
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.status || 'new'}>
                  <option value='active'>Active</option>
                  <option value='inactive'>Inactive</option>
                  <option value='archived'>Archived</option>
                </select>
              </div>
              <div className='form-group w-25'>
                {' '}
                <label className='form_label' htmlFor='is_volunteer'>
                  Volunteer?
                </label>
                <select
                  className='form-control'
                  placeholder='Is Leader'
                  name='is_volunteer'
                  onChange={this.handleItemDetailFormChange}
                  value={contactDetail.is_volunteer || '0'}>
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </select>
              </div>

              <div className='form-group'>
                {this.state.error ? (
                  <p className='text-danger'>{this.state.error}</p>
                ) : null}
              </div>
            </form>
            <div
              onClick={this.handleItemSaveButton}
              className='small btn btn-success mx-5'>
              Save Changes
            </div>
            {!this.props.isNew ? (
              <div
                onClick={this.handleItemDeleteButton}
                className='small btn btn-danger'>
                Delete this Contact
              </div>
            ) : (
              ''
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}
